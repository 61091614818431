import React from 'react';
import { AccessTime, DirectionsCar } from "@mui/icons-material";
import { createTheme, ThemeProvider, Stack, Grid, Typography } from "@mui/material";
import { Gallery, Gallery2, Contact, Navbar} from "./components";

const theme = createTheme({
    typography: {
        fontFamily: [
            'monospace',
        ].join(','),
    }
});

export const App = () => (
    <ThemeProvider theme={theme}>
        <Stack spacing={5}>
            <Navbar />

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Stack spacing={2}>
                        <Typography variant="h4" textAlign="center">
                            Vítejte na Klínovci, v srdci Krušných hor
                        </Typography>
                        <Typography textAlign="center">
                            Jsme mladá rodina - Martin a Veronika se třemi dětmi - a stavíme dva horské domy v Háji u Loučné
                            pod Klínovcem.
                        </Typography>
                        <Typography textAlign="center">
                            Hledáme k sobě sousedy, kteří si jeden z našich domů pořídí pro sebe a své přátele a budou si
                            zde užívat letní i zimní radovánky, kola, lyže i procházky a běžný relax vzdálený dnešní
                            zrychlené době.
                        </Typography>
                        <Typography textAlign="center">
                            Přijďte se k nám podívat a určitě si to tu zamilujete, stejně jako my.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

            <Gallery />

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Stack spacing={2}>
                        <Typography variant="h4" textAlign="center">
                            Na co se můžete těšit?
                        </Typography>
                        <Typography textAlign="center">
                            Pozemků je v Krušných horách jak šafránu. Ten náš s parcelním č. 612/67, kde se nabízený dům
                            nachází, se vám navíc bude líbit. Je na klidném, slunném místě s přímým výhledem na Klínovec
                            , lyžařské středisko i protější německý Fichtelberg. Má rozlohu 932 m2, tak akorát, aby bylo
                            dost místa na aktivity kolem a přitom se snadno udržoval.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Typography textAlign="center">
                        Samotný dům má dispozici 5+kk a nabídne vám užitnou plochu 146 m2. Navíc oceníte terasu a kryté
                        parkovací stání s přiléhající praktickou kůlnou na sportovní či zahradní vybavení. Je to moderní
                        novostavba, která má minimální náklady na energie a cítíte se v ní příjemně v létě i v zimně.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Typography textAlign="center">
                        Dlouho jsme promýšleli každý detail domu společně s architektonickým studiem Mooza. Těšit se tak
                        můžete na vysoký standard vnitřního vybavení, prosklený štít, hliníková okna, dřevěné podlahy,
                        kvalitní obklady a dlažby, podlahové vytápění ovládané na dálku či přípravu pro infrasaunu a
                        krbová kamna.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Typography textAlign="center">
                        Dům má nyní dokončenou hrubou stavbu, a je tak možné si ještě spoustu věcí upravit podle svého
                        přání a vkusu. Náš vedlejší dům vám může sloužit pro inspiraci.
                    </Typography>
                </Grid>
            </Grid>

            <Gallery2 />

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Stack spacing={2}>
                        <Typography variant="h4" textAlign="center">
                            Dům takový, jaký si vysníte
                        </Typography>
                        <Typography textAlign="center">
                            Jako vaši budoucí sousedé vám vaši novou horskou základnu rádi přizpůsobíme, aby jste se v
                            ní cítili dobře a vyhovovala vašemu životnímu rytmu.
                        </Typography>
                        <Typography textAlign="center">
                            Nejsme developeři, ale přímí majitelé, a tak vám také můžeme vyjít vstříc se stavebními
                            úpravami podle vašich přání a také s flexibilním financováním.
                        </Typography>
                        <Typography textAlign="center">
                            Nyní máme dům ve stavu dokončené hrubé stavby a napojení na sítě a rádi vám ji buď dostavíme
                            na klíč podle vašich představ a nebo si ji můžete dokončit i sami individuálně. Doba
                            potřebná k dokončení je 5 měsíců. Náš vedlejší dům vám může sloužit pro inspiraci.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Typography textAlign="center">
                        Cena za kompletně dokončenou nemovitost na klíč je <b>13.300.000 korun</b>.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Typography textAlign="center">
                        Cena za nemovitost v aktuálním stavu dokončenosti je <b>9.890.000 korun</b>.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Stack spacing={2}>
                        <Typography variant="h4" textAlign="center">
                            Přijďte se s námi potkat na Klínovci
                        </Typography>
                        <Typography textAlign="center">
                            Není nic lepšího, než osobní zkušenost. Domluvte si s námi schůzku v Praze nebo nejlépe
                            rovnou na Klínovci a společně si řekneme vše, co vás bude zajímat.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d29760.550377299605!2d12.992630710987982!3d50.413642824149846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTDCsDI0JzQ3LjMiTiAxMsKwNTknNDAuMSJF!5e0!3m2!1scs!2scz!4v1715025115840!5m2!1scs!2scz"
                    width="600"
                    height="450"
                    allowFullScreen={true}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6} sx={{ textAlign: "center" }}>
                    <Stack spacing={1}>
                        <Stack spacing={1} direction="row" alignItems="center" justifyContent="center">
                            <AccessTime fontSize="small" />
                            <Typography textAlign="center">
                                1:30h z Prahy
                            </Typography>
                        </Stack>
                        <Stack spacing={1} direction="row" alignItems="center" justifyContent="center">
                            <DirectionsCar fontSize="small" />
                            <Typography textAlign="center">
                                125 km
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Typography textAlign="center">
                        Těšíme se na vás!
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={10} md={7}>
                    <Typography textAlign="center">
                        Martin a Veronika
                    </Typography>
                </Grid>
            </Grid>

            <Contact />

            <img id="footer-img" src="img/footer.jpg" width="100%" alt="footer"/>
        </Stack>
    </ThemeProvider>
);
